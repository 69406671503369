<template>
  <div>
    <div class="studyway animate__animated animate__fadeIn">
      <div class="main">
        <div @click="link(data, index)" class="box animate__animated animate__rollIn" :style="'animation-delay:' + (index*0.3 + 0.5) + 's'" v-for="(data, index) in list" :key="data.id">
          <img class="studywayIconImg" :src='"../../../assets/images/student/studywayIcon" + (index%8+1) + ".png"' alt="">
          <div class="text">{{ data.mc }}</div>
        </div>
      </div>
    </div>
    <MainHeader :header_data="[{ title: '学习之旅' }]" />
    <Footer :flag="false" />
  </div>
</template>

<script>
import MainHeader from "@/components/student/header";
import { wordType, isHaveBb } from '@/api/student'
export default {
  components: {
    MainHeader,
    Footer: (resolve) => {
      require(['@/components/student/footer.vue'], resolve)
    }
  },
  data() {
    return {
      list: [],
      isCheck: 0, // 除了自然拼读，是否可以进去 0不可以1可以
    }
  },
  created() {
    wordType({ xsid: JSON.parse(sessionStorage.getItem('userInfo')).yhid }).then(res => {
      if (res.success) {
        // this.isCheck = res.result.isCheck
        this.list = res.result
      } else {
        this.$message.error(res.resultDesc)
      }
    })
  },
  methods: {
    back() {
      const main = document.getElementsByClassName('main')[0];
      const studyway = document.getElementsByClassName('studyway')[0];
      main.classList.add('animate__animated', 'animate__fadeOutRight')
      setTimeout(() => {
        studyway.classList.add('animate__animated', 'animate__fadeOut')
        setTimeout(() => {
          this.$router.replace({ name: 'home' })
        }, 600)
      }, 300)
    },
    link(data, index) {
      let jxInfo = {
        lxid: null,
        lxmc: null
      };
      jxInfo.lxid = data.id;
      jxInfo.lxmc = data.mc;
      sessionStorage.setItem('jxInfo', JSON.stringify(jxInfo));
      isHaveBb({ lxid: data.id }).then(res => {
        if (res.success) {
          if (res.result.length) {
            sessionStorage.setItem('bbInfo', JSON.stringify(res.result))
            const box = document.getElementsByClassName('box')
            for (let i = 0; i < box.length; i++) {
              if (i !== index) {
                box[i].style.animationDelay = '0s'
                box[i].className = 'box'
                box[i].classList.add('animate__animated', 'animate__fadeOutRight')
              } else {
                box[i].style.animationDelay = '0.3s'
                box[i].className = 'box'
                box[i].classList.add('animate__animated', 'animate__bounceOut')
                const studyway = document.getElementsByClassName('studyway')[0];
                setTimeout(() => {
                  studyway.classList.add('animate__animated', 'animate__fadeOut')
                  setTimeout(() => {
                    this.$router.replace({ name: 'books', query: { type: data.mc, bbid: res.result[0].id, bbmc: res.result[0].mc } })

                  }, 500)
                }, 800)
              }
            }
          } else {
            this.$message.warning('当前模块暂无版本')
          }
        } else {
          this.$message.error(res.resultDesc)
        }
      })
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1367px) {
  .studyway {
    .main {
      width: 75% !important;
    }
  }
}
.studyway {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url("../../../assets/images/student/studywayBanner.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .main {
    width: 60%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .box {
      float: left;
      width: 25%;
      box-sizing: border-box;
      padding: 0px 10px;
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
      }
      .text {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: #333;
        font-size: 1.37vw;
        font-weight: bold;
      }
    }
  }
}
</style>
<style lang="scss">
</style>



